import { Component, OnInit,ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BarcodeScanner,CameraDirection } from '@capacitor-community/barcode-scanner';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss'],
})
export class SigninPage implements OnInit {
vadm:boolean=false;
scanActive:boolean=false;
authcode:string;
requestHeaders:any;
errorMessage:any;
allowToClose:boolean=false;
  constructor(private http: HttpClient,public modalController: ModalController) {
    this.requestHeaders = new HttpHeaders();
  this.requestHeaders.append('Content-Type', 'application/json');
  this.vadm=(localStorage.getItem("vadm")=="1");
  if (this.vadm){this.allowToClose=true;}
  if (localStorage.getItem("apiKey")){this.allowToClose=true;}

  }

  ngOnInit() {
  }
  async checkPermission() {
  return new Promise(async (resolve, reject) => {
    const status = await BarcodeScanner.checkPermission({ force: true });
    if (status.granted) {
      resolve(true);
    } else if (status.denied) {
      BarcodeScanner.openAppSettings();
      resolve(false);
    }
  });
}

async startScanner() {

  (window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
  this.scanActive = true;

  const allowed = await this.checkPermission();
  if (allowed) {
    this.scanActive = true;
    BarcodeScanner.hideBackground();

    const result = await BarcodeScanner.startScan();

    if (result.hasContent) {
      this.scanActive = false;
      this.authcode=result.content;
      this.stopScanner();
      this.envoyerCode();
      //The QR content will come out here
      //Handle the data as your heart desires here
    } else {
      alert('NO DATA FOUND!');
      this.stopScanner();
    }
  } else {
    alert('NOT ALLOWED!');
    this.stopScanner();
  }
}
stopScanner() {
  BarcodeScanner.stopScan();
  (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
  this.scanActive = false;
}
ionViewWillLeave() {
  BarcodeScanner.stopScan();
  (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
  this.scanActive = false;
}


  setauthcode(code){
    console.log(code);
    this.authcode=code.target.value;
  }
  startScanner_old(){
    this.scanActive = true;
  /*  this.barcodeScanner.scan().then(barcodeData => {
     this.authcode=barcodeData['text'];
     this.envoyerCode();
     console.log('Barcode data', barcodeData);
     this.scanActive = false;
    }).catch(err => {
        console.log('Error', err);
    });*/
  }
  envoyerCode(){
    let url = "https://vata.eco/api/checkin/"+this.authcode;
    console.log(url);
this.http.get(url).subscribe((res:any) => {
  console.log(res);
  if (res['success']){
    if (localStorage.getItem("apiKey")){
      if (localStorage.getItem("apiKeys")){
        let apiKeys=JSON.parse(localStorage.getItem("apiKeys"));

        if (!apiKeys.some((key)=>key==res['apikey'])){
          apiKeys.push(res['apikey']);
          localStorage.setItem("apiKeys",JSON.stringify(apiKeys));
        }
      }
      else {
        let apiKeys=[];
        apiKeys.push(res['apikey']);
        localStorage.setItem("apiKeys",JSON.stringify(apiKeys));
      }
    }
    else {
      let apiKeys=[];
      apiKeys.push(res['apikey']);
      localStorage.setItem("apiKeys",JSON.stringify(apiKeys));
    }
    localStorage.setItem("apiKey",res['apikey']);
    localStorage.removeItem("logoCompany");
    localStorage.removeItem("user");
    localStorage.removeItem("profilePicture");
    localStorage.removeItem("codeUrl");
    localStorage.removeItem("vadm");
    window.location.reload();
  }
  else {
    this.errorMessage=res['message']
  }
})
  }
  closeModal() {
    this.modalController.dismiss();
}
}
