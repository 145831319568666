import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'user-checkin',
    loadChildren: () => import('./user-checkin/user-checkin.module').then( m => m.UserCheckinPageModule)
  },
  {
    path: 'edit-user-modal',
    loadChildren: () => import('./edit-user-modal/edit-user-modal.module').then( m => m.EditUserModalPageModule)
  },
  {
    path: 'edit-user-params',
    loadChildren: () => import('./edit-user-params/edit-user-params.module').then( m => m.EditUserParamsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
