import { Component } from '@angular/core';
import { ThemeService } from './theme.service';
import { ModalController } from '@ionic/angular';
import { SigninPage } from './signin/signin.page';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user:any={nom:"Anthony Chadburn"};
  public selectTheme;
  public apiKey;

  constructor(private theme: ThemeService,public modalController: ModalController,private http: HttpClient,private screenOrientation: ScreenOrientation) {
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    if (localStorage.getItem("theme")){
      this.selectTheme=localStorage.getItem("theme");
    }
    else{
        this.selectTheme = 'Dark';
    }
    if (localStorage.getItem("apiKey")){
      this.apiKey=localStorage.getItem("apiKey");
      this.loadAPIData();
    }
    else {
      this.presentModal();
    }
    console.log(this.selectTheme);
    this.dynamicTheme();

  }
  async presentModal() {
  const modal = await this.modalController.create({
    component: SigninPage,
    keyboardClose:false,
    swipeToClose:false,
    backdropDismiss:false,

  });
  return await modal.present();
}

async loadAPIData() {
  let url = "https://vata.eco/api/getvcard/"+this.apiKey;
  console.log(url);
  this.http.get(url).subscribe((res:any) => {
  console.log('load api');
  if (res['success']){

    let userData=JSON.stringify(res['user']['vcarddata']);
    console.log(res);
    if (res['company']['params']['application_settings']['default_theme'] && res['company']['params']['application_settings']['allowThemeChange']=="false" ){
      localStorage.setItem("theme",res['company']['params']['application_settings']['default_theme']);
      this.selectTheme=res['company']['params']['application_settings']['default_theme'];
      this.dynamicTheme();
    }
    else if (res['user']['vcarddata']['selectTheme']){
      localStorage.setItem("theme",res['user']['vcarddata']['selectTheme']);
      this.selectTheme=res['user']['vcarddata']['selectTheme'];
      this.dynamicTheme();
    }
    localStorage.setItem("user",userData);
    localStorage.setItem("codeUrl",res['url_code']['qrc']['code_url']);
    localStorage.setItem("vadm",res['params']['v_admin']);
    localStorage.setItem("allowThemeChange",res['company']['params']['application_settings']['allowThemeChange']);
    localStorage.setItem("allowLogoChange",res['company']['params']['application_settings']['allowLogoChange']);
    if (res['logo_company']){
      localStorage.setItem("logoCompany",res['logo_company']);
    }
    if (res['profile_picture']){
      localStorage.setItem("profilePicture",res['profile_picture']);
    }
  }
  })
}

  dynamicTheme() {
      this.theme.activeTheme(this.selectTheme);
      let data=this.selectTheme;
      localStorage.setItem("theme",data);
  }
}
